import React from "react";

import '../../App.scss';

import Header from "./HeaderView";
import Footer from "./FooterView";
import BgAni from "./bgAniView";
import Section from "./SectionView";
import DomainSearch from "./DomainSearchView";

const DesktopView = ({items}) => {

    return(
        <>
            <BgAni/>
            <Header/>
            <DomainSearch/>
            <Section items={items}/>
            <Footer/>
        </>
    );

};

export default DesktopView;