import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const SectionView = ({items}) => {

    return(
        <>
            <section className="section-page bg-light">
                <Container>
                    <Row>
                        { items.section.map((index) => (
                            <Col key={index.key} md={4}>{index.headline}</Col>
                        )) }
                    </Row>
                </Container>
            </section>
        </>
    );

};

export default SectionView;