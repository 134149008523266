import React from "react";
import { Container, Row, Col, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const DomainSearchView = ({items}) => {

    return(
        <>
            <div className="DomainSearch">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <Form.Label className="w-100 text-center">
                                Her kan du søge efter enhver domain og eje et eller søg et.
                            </Form.Label>
                            <InputGroup size="lg">
                                <FormControl id="inlineFormInputGroup" placeholder="Domain..." />
                                <Button type="submit" title="Søg efter domain">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );

};

export default DomainSearchView;