import './App.scss';
import Content from './components/content';
import myData from "./assets/json/settings.json";

const App = () => {
  
  return <Content items={myData}/>;

};

export default App;
