import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Dropdown, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

const HeaderView = ({items}) => {
    useEffect(() => {
        document.addEventListener("DOMContentLoaded", function(event){
            if (window.innerWidth > 992) {
            
                document.querySelectorAll('.dropdown').forEach(function(everyitem){
            
                    everyitem.addEventListener('mouseover', function(e){
            
                        let el_link = this.querySelector('a[data-bs-toggle]');
            
                        if(el_link != null){
                            let nextEl = el_link.nextElementSibling;
                            el_link.classList.add('show');
                            nextEl.classList.add('show');
                        }
            
                    });
                    everyitem.addEventListener('mouseleave', function(e){
                        let el_link = this.querySelector('a[data-bs-toggle]');
            
                        if(el_link != null){
                            let nextEl = el_link.nextElementSibling;
                            el_link.classList.remove('show');
                            nextEl.classList.remove('show');
                        }
            
            
                    })
                });
            }
        }); 
        
    }, []);

    return(
        <>
            <Navbar expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand href="#home" className="text-uppercase fw-bold"><span className="custom-v">V</span>ALOX</Navbar.Brand>
                    <Navbar.Brand href="#home" className="side-text">Web<hr className="custom-hr"/>Designer</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Item><Nav.Link href="#home" className="text-uppercase active">Forside</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link href="#home" className="text-uppercase">Blog</Nav.Link></Nav.Item>
                            <NavDropdown title="Information" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Produkter</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Dokumentation</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Bliv en del af holdet</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className="ms-auto">
                            <li className="dropdown mx-1">
                                <Nav.Link className="btn btn-success px-3 py-2" href="#home" data-bs-toggle="dropdown" title="Login"><FontAwesomeIcon icon={faSignInAlt} /></Nav.Link>
                                <ul className="dropdown-menu dropdown-menu-end" style={{right: '0'}}>
                                    <NavDropdown.Item href="#action/3.1">Log ind</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.1">Opret en konto</NavDropdown.Item>
                                </ul>
                            </li>
                            <li className="dropdown mx-1">
                                <Nav.Link className="btn btn-success px-3 py-2" href="#home" data-bs-toggle="dropdown" title="Login"><FontAwesomeIcon icon={faShoppingBasket} /><Badge className="ms-2 p-0" bg="transparent">0</Badge></Nav.Link>
                                <ul className="dropdown-menu dropdown-menu-end" style={{right: '0'}}>
                                    <Dropdown.Header>Du har ingen vare i din kurv</Dropdown.Header>
                                </ul>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );

};

export default HeaderView;