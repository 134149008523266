import React from "react";

const bgAniView = ({items}) => {

    return(
        <>
            <div className="bg"></div>
            <div className="bg bg2"></div>
            <div className="bg bg3"></div>
        </>
    );

};

export default bgAniView;